import React from 'react';
import CommonContents from './components/Common-page-layout';

const Content: React.FC = (): JSX.Element => {
  return(
    <p>
      このページは存在しません。
    </p>
  );
}

const NoMatch: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='エラー'
      subtitle='404 Not Found'
      children={<Content />}
      />
    </>
  );
}

export default NoMatch;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { middleWidth, smallWidth, menuLists, naviLists, OverlayPlate } from './Commmons';
import '../index.css';
import { CSSTransition } from 'react-transition-group';
import { SlideMenu } from './Slide-menu'
import { OverlayTransitionStyle } from '../components/Commmons';
import { icons } from './commons/icons';
import { images } from './commons/images';

const MemoSlideMenu = React.memo(SlideMenu);

const NavHead = styled.header`
  margin: 0;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 50;
  background: #0e0e0e;
  border-radius: 10px;
  box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.5);
`;

const NavGuide = styled.nav`
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;

  #hamburger {
    display: none;
    
    @media (max-width: ${smallWidth}) {
      display: contents;
      cursor: pointer;
    }

    img {
      display: block;
      margin: auto 10px;
      padding: 5px;
      transition: opacity .3s;
      
      &:hover {
        opacity: .5;
        transition: opacity .3s;
      }
      
      &:active {
        border: #fff 1px solid;
        padding: 4px;
        border-radius: 2px;
      }
    }
  }

  #backToHome {
    display: flex;
    height: 100%;
  }
  
  #univLogo {
    width: 20%;
    line-height: 70px;
    background: var(--color-black);

    @media (max-width: ${smallWidth}) {
      width: 200px;
    }
  }
  
  #univName {
    font-size: 1.2rem;
    color: #DCE0D9;
    letter-spacing: .3rem;
    transition: letter-spacing 0.5s;
    
    @media (max-width: ${middleWidth}) {
      letter-spacing: initial;
    }
  }
  
  #univLogo:hover #univName {
    letter-spacing: .5rem;
    transition: letter-spacing 0.5s;

    @media (max-width: ${middleWidth}) {
      letter-spacing: initial;
    }
  }
`;

const HamburgerTransitionStyle = styled.div`
  .fadeHamburgerMenu-enter {
    opacity: 0;
    transform: translateX(200px);
  }
  
  .fadeHamburgerMenu-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: .5s all;
  }
  
  .fadeHamburgerMenu-exit {
    opacity: 1;
  }
  
  .fadeHamburgerMenu-exit-active {
    opacity: 0;
    transition: .5s all;
    transform:translateX(200px);
  }
`;

const NavUl = styled.ul`
  margin: 0;
  padding-left: 0;
  display: flex;
  width: 90%;

  @media (max-width: ${smallWidth}) {
    display: none;
  }

  .category {
    width: 100%;
    text-decoration: none;
    color: #fff;
  }

  .navLi {
    background: var(--color-brown);
    font-size: 1.3rem;
    width: 99%;
    margin: 0;
    text-align: center;
    font-weight: bold;
    top: 0;
    padding: 20px 0;
    user-select: none;
    transition: font-size 0.5s;
    border-radius: 5px;


    @media (max-width: ${middleWidth}) {
      font-size: 1rem;
    }
    
    &:hover {
      font-size: 1.5rem;
      background: #33658A;
      transition: .5s all;

      @media (max-width: ${middleWidth}) {
        font-size: 1.2rem;
        background: #33658A;
        transition: .5s all;
      }
    }
  }
`; 

const NavSlide = styled.div`

  #slideMenu {
    width: 100%;
    height: 100px;
    background: #62343A;
    position: absolute;
    line-height: 50px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.5);
    
    @media (max-width: ${middleWidth}) {
      height: 80px;
      line-height: 25px;
    }
  }
  
  .menuFade-enter {
    opacity: 0;
    transform: translateX(-200px);
  }
  
  .menuFade-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: .5s all;
  }
  
  .menuFade-exit {
    opacity: 1;
  }
  
  .menuFade-exit-active {
    opacity: 0;
    transition: .5s all;
    transform:translateX(200px);
  }
`;
  
const SlideUl = styled.ul`
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  .menuLi {
    font-size: 1.8rem;
    margin: 30px;

    @media (max-width: ${middleWidth}) {
      font-size: 1.5rem;
      transition: .5s all;
    }
    
    &::before {
      content: "＞";
    }
  
    .menuAnker {
      text-decoration: none;
      color: #DCE0D9;
      transition: .5s;
    }
    
    .menuAnker:hover{
      color: #fff;
      font-size: 1.1em;
    }
    
    .menuAnker::after{
      background-color: tomato;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      transition: .3s all;
      width: 0;
    }
    
    .menuAnker:hover::after{
      width: 100%;
    }
  }
`;


const Header: React.FC = (): JSX.Element => {
  const [hoverStatus, setHoverStatus] = useState<boolean>(false);
  const [navShowNum, setNavShowNum] = useState<number>(0);
  const [burgerStatus, setBurgerStatus] = useState<boolean>(false);
    
  const showMenu = (e: any): void => {
    setNavShowNum(e.target.dataset.naviId as number);
    setHoverStatus(true);
  }

  const stateToShowMenu = ():void => {
    setNavShowNum(navShowNum);
    setHoverStatus(true);
  }

  return (
    <NavHead>
      <NavGuide>
        <div id='univLogo'>
          <Link to='/' id='backToHome'>
            <img src={images.logo} alt="大学ロゴ" />
            <div id='univName'>根津増大学</div>
          </Link>
        </div>
        <NavUl>
          {naviLists.map((naviList) =>
            <Link to={naviList.url} className='category' key={naviList.naviId}>
              <li
              className='navLi'
              onMouseOver={showMenu}
              onMouseOut={() => setHoverStatus((prev) => !prev)}
              data-navi-id={naviList.naviId}>
                {naviList.title}
              </li>
            </Link>
          )}
        </NavUl>

        <div
        id='hamburger'
        onClick={() => setBurgerStatus((prev) => !prev)}
        >
          <img src={icons.hamburger} alt="メニューボタン" width="40px" />
        </div>
      </NavGuide>
      <HamburgerTransitionStyle>
        <CSSTransition
        classNames="fadeHamburgerMenu"
        in={burgerStatus}
        timeout={500}
        unmountOnExit>
          <MemoSlideMenu setBurgerStatus={setBurgerStatus} />
        </CSSTransition>
      </HamburgerTransitionStyle>

      <OverlayTransitionStyle>
        <CSSTransition
        classNames="overlay"
        in={burgerStatus}
        timeout={500}
        unmountOnExit>
          <OverlayPlate onClick={() => setBurgerStatus((prev) => !prev)} />
        </CSSTransition>
      </OverlayTransitionStyle>

      <NavSlide>
        <CSSTransition
        classNames="menuFade"
        in={hoverStatus}
        timeout={500}
        unmountOnExit>
          <div
          id='slideMenu'
          onMouseOver={stateToShowMenu}
          onMouseOut={() => setHoverStatus((prev) => !prev)}
          data-navi-id={navShowNum}>
            <SlideUl>
              {menuLists[navShowNum].article.map((menuListArticle, i) =>
                <li className='menuLi' key={i}>
                  <Link to={menuListArticle.url} className='menuAnker'>
                    {menuListArticle.title}
                  </Link>
                </li>
              )}
            </SlideUl>
          </div>
        </CSSTransition>
      </NavSlide>
    </NavHead>
  );
}

export default Header;
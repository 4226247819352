import election from '../../Icons/election.svg'
import phone from '../../Icons/phone.svg'
import bitcoin from '../../Icons/bitcoin.svg'
import atm from '../../Icons/atm.svg'
import pigeon from '../../Icons/pigeon.svg'
import festival from '../../Icons/festival.svg'
import communication from '../../Icons/communication.svg'
import hamburger from '../../Icons/hamburger.svg'
import cross from '../../Icons/cross.svg'
import twitter from '../../Icons/twitter.svg'

export const icons = {
  election: election,
  phone: phone,
  bitcoin: bitcoin,
  atm: atm,
  pigeon: pigeon,
  festival: festival,
  communication: communication,
  hamburger: hamburger,
  cross: cross,
  twitter: twitter
}
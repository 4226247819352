import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { commonLineHeight, commonPSize, ContentsFadeIn, middleWidth, smallH1Size, smallLineHeight, smallPSize, smallWidth } from './Commmons';
import { CSSTransition } from 'react-transition-group';

const CommonPageLayout = styled.div`
  margin: 100px 0 40px;
  width: 100%;
  height: 100%;

  @media (max-width: ${smallWidth}) {
    margin: 50px 0;
  }

  .position {
    position: relative;

    h1{
      margin: 0 30px 80px 30px;
      padding: 30px;
      border-bottom: 1px solid #fff;
      
      @media (max-width: ${smallWidth}) {
        margin: 0 30px 20px 30px;
        padding: 30px 0 10px;
        text-align: left;
        font-size: ${smallH1Size};
      }
    }
  
    h2{
      font-size: 5vw;
      opacity: 0.1;
      position: absolute;
      top: 0;
      right: 5%;
      z-index: -1;
      margin: 0;
      
      @media (max-width: ${smallWidth}) {
        top: 40px;
        right: 10%;
        font-size: 1rem;
      }
    }
  }

  h3 {
    font-size: 2rem;
    margin-top: 100px;
    text-align: center;

    &::after,
    &::before
    {
      content: " ◎ ";
    }

    @media (max-width: ${smallWidth}) {
      margin: 0;
      font-size: 1.1rem;
    }
  }

  p {
    margin: 30px;
    font-size: ${commonPSize};
    line-height: ${commonLineHeight};

    @media (max-width: ${middleWidth}) {
      line-height: 2.5rem;
    }
    
    @media (max-width: ${smallWidth}) {
      margin: 20px;
      font-size: ${smallPSize};
      line-height: ${smallLineHeight};
      font-weight: normal;
    }
  }
`;

type CommonContentsProps = {
  title: string,
  subtitle: string,
  children: JSX.Element,
}

const CommonContents: React.FC<CommonContentsProps> = (props): JSX.Element => {

  const [readEndstatus, setReadEndstatus] = useState<boolean>(false);
  
  useEffect(() => {
    document.title = props.title;
    setReadEndstatus(true);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [setReadEndstatus, props.title]);
  
  return(
    <ContentsFadeIn>
      <CSSTransition
      classNames="readEnd"
      in={readEndstatus}
      timeout={1000}
      unmountOnExit>
        <CommonPageLayout>
          <div className='position'>
            <h1>{props.title}</h1>
            <h2>{props.subtitle}</h2>
          </div>
          {props.children}
        </CommonPageLayout>
      </CSSTransition>
    </ContentsFadeIn>
  );
}

export default CommonContents;
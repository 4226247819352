import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { images } from '../../components/commons/images';

export const Gallery: React.FC = (): JSX.Element => {
  const image = [
    { original: images.classroom },
    { original: images.pcroom },
    { original: images.auditorium },
  ];

  return(
    <div style={{marginTop: "70px"}}>
      <ImageGallery
        items={image}
        showFullscreenButton={false}
        showNav={false}
        showPlayButton={false}
        autoPlay={true}
        showBullets={true}
      />
    </div>
  );
}

import React, { SetStateAction, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { menuLists, naviLists } from "./Commmons";
import { icons } from "./commons/icons";


type CloseStatus = {
  setBurgerStatus: React.Dispatch<SetStateAction<boolean>>;
}

const SlideMenuStyle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  padding: 0 0 50px;
  z-index: 1000;
  background-color: #eee;
  background-color: var(--color-blue);
  overflow-y: scroll;
  

  #closeMenu {
    cursor: pointer;
    margin: 10px;
    text-align: right;

    img {
      padding: 5px;
      transition: opacity .3s;
      
      &:hover {
        opacity: .5;
        transition: opacity .3s;
      }

      &:active {
        border: var(--color-black) 1px solid;
        padding: 4px;
        border-radius: 2px;
      }
    }
  }

  #menu {
    margin: 0 0 100px;
  }
  
  a {
    color: #fff;
    transition: .3s all;
    
    &:hover {
      letter-spacing: .2rem;
      transition: .3s all;
    }
  }
  
  h4 {
    font-size: 1.2rem;
    padding: 5px 10px;
    border-radius: 10px 0 0 10px;
    border: 1px solid var(--color-pink);
    border-right: none;
    margin: 30px 0 5px;
  }
  
  li {
    font-size: 1.1rem;
    text-align: right;
    padding: 5px;
    margin: 0 20px 0 0;
  }
  
  li + li {
    border-top: solid 1px #D0CDCD;
  }
`;

type MenuType = {
  num: number;
  fanc?: () => void;
}

export const ShowMenuLists: React.FC<MenuType> = (props): JSX.Element => {

  return(
    <>
      {menuLists[props.num].article.map((menuListArticle, i) =>
        <li key={i}>
          <Link
          to={menuListArticle.url}
          onClick={props.fanc}
          className="footterLink"
          >
            {menuListArticle.title}
          </Link>
        </li>
      )}
    </>
  )
}

const stopScrollingBackContent = () => {
  document.body.style.overflow = "hidden";
  return () => {
    document.body.style.overflow = "auto";
  }
};

export const SlideMenu: React.FC<CloseStatus> = ({setBurgerStatus}): JSX.Element => {
  // 背景のスクロール禁止処置
  useEffect(stopScrollingBackContent, []);

  const closeMenu = (): void => {
    setBurgerStatus(false);
  }

  let ulArray: JSX.Element[] = [];

  for (let i = 0; i < naviLists.length; i++) {
    ulArray.push(
      <ul key={i}>
        <h4>
          <Link to={naviLists[i].url} onClick={closeMenu}>
            {naviLists[i].title}
          </Link>
        </h4>
        <ShowMenuLists num={i} fanc={closeMenu}/>
      </ul>
    );
  }
  
  return (
    <SlideMenuStyle>
      <div id="closeMenu">
        <img src={icons.cross} alt="閉じる" width="30px" onClick={closeMenu}/>
      </div>
      <div id="menu">
        {ulArray}
      </div>
    </SlideMenuStyle>
  );
}